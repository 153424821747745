import { ButtonContent } from "../../global/boton/ButtonContent";

function HeroSection({ title, texts, urlVideo }) {


  return (
    <section className={'svgBoxRectanguloBottonRigth'}>

      <div>
        <div className="w-full relative content_video flex justify-center">
          <video

            playsInline
            autoPlay
            muted
            loop
            className="w-full md:h-full object-cover"
          >
            <source
              src={urlVideo}
              type="video/mp4"
            />
          </video>
          <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
            <div className="w-full text-center p-4">
              <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{title}</h1>
              <p className="px-5 md:px-[20%]">{texts}</p>
              <ButtonContent btnStyle="three" />
            </div>
          </div>
        </div>
      </div>


    </section>

  )
}

export default HeroSection;
