import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

const Paletacolor = () => {

    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='w-full mx-auto mt-0 md:my-10 py-12 border-t-[15px] border-b-[15px] border-double borderColor2'>
            <h2 className='capitalize text-center px-4 text-[#FF7507]'>
                {
                    rpdata?.labels?.general?.titleBranding ?
                        rpdata?.labels?.general?.titleBranding
                        :
                        'Choose your favorite color through the following color palettes'
                }
            </h2>
            <div className='flex flex-wrap justify-center items-center gap-8'>
                {
                    rpdata?.brandingExtra?.map((item, index) => {
                        return (
                            <a href={item.link} target="_blank" rel="noreferrer" key={index}>
                                <img src={item.img} alt='no found' />
                            </a>
                        )
                    })
                }


            </div>
        </div>

    )
}


export default Paletacolor;